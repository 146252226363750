import * as React from "react";
import { Stack, Chip } from "@mui/material";
import { navigate } from "gatsby";

export interface IBlogPostTagsProps {
  tags: string[];
}

const navigateToTagPage = (tag: string) => navigate(`/blog/tags/${tag}`);

export function BlogPostTags(props: IBlogPostTagsProps) {
  return (
    <Stack direction="row" spacing={1}>
      {props.tags.map((x) => {
        return <Chip label={x} key={x} onClick={() => navigateToTagPage(x)} />;
      })}
    </Stack>
  );
}
